import gql from 'graphql-tag'

export const GET_CLIENT_BY_ID_OR_MEMBER_ID = gql`
query ($id:String!) {
    getClientByIdOrMemberId(id: $id) {
      id
      memberId
      contracts {
        id
        productType {
          id
          name
        }
        endDate
      }
      name
      firstName
      lastName
      firstKana
      lastKana
      
      mainContact {
        tel
        prefecture
        locality
        address1
        address2
      }
      
      subMembers { 
        id
        subMemberType {name}
        name
        tel
        prefecture
        locality
        address1
        address2
      }
    }
  }
`
