<template>
  <div class="d-flex align-baseline p-relative">
    <!-- Search All -->
      <v-card-title class="pa-0">
        <v-text-field
          :placeholder="$t('reservation.searchAllReservations')"
          append-icon="mdi-magnify"
          :loading="!!searchTimer"
          clearable
          hide-details
          v-model="keyword"
          class="search-text-field"
        >
        </v-text-field>
        </v-card-title>
          <!-- v-model="keywords" -->
          <!-- v-if="keywords !== '' && keywords !== null" -->
        <v-card
          v-if="showListSearch === true"
          class="search-results"
          v-click-outside="onClickOutside"
          max-width="400"
        >
        <v-card-text  class="pa-0">
          <div v-if="listSearchBooking.length > 0">
            <!-- 予約ID - Reservation ID -->
            <div class="pt-3 pl-3 pb-4" style="color: #212121; font-size: 18px; font-weight: 400">予約ID</div>
            <div
              v-for="(item) in list.reservationID"
              :key="item.id"
              class="pa-2 search-results__item"
            >
              <v-row :class="'search-row ' + itemRowBackgroundId(item)" @click="handleClickRow(item)">
                <v-col cols="4" class="col-left">
                  <p>{{ item.id }}</p>
                  <p>{{ formatDateSlash(item.checkInDate)}}</p>
                  <p>{{ item.facilityName }}</p>
                </v-col>
                <v-col cols="8" class="col-right">
                  <p class="text-right">検索対象: {{ item.keywordHitIn }}</p>
                  <p>{{ item.representativeName }}</p>
                  <p :style="item.representativeName ? '' : 'margin-top: 20px;'">
                    <span v-if="item.memberId">会員番号{{ item.memberId}}</span>
                    <span v-if="item.clientId">{{ item.memberId ? ' ': ''}}顧客ID{{ item.clientId}}</span>
                  </p>
                </v-col>
              </v-row>
            </div>

            <!-- 会員番号 - Member ID if(list.memberID.length > 0) -->
            <template v-if="list.memberID.length > 0">
              <div class="pt-3 pl-3 pb-4" style="color: #212121; font-size: 18px; font-weight: 400">会員番号</div>
              <div
                v-for="(item) in list.memberID"
                :key="item.id"
                class="pa-2 search-results__item"
              >
                <v-row :class="'search-row ' + itemRowBackgroundId(item)" @click="handleClickRow(item)">
                  <v-col cols="4" class="col-left">
                    <p>{{ item.id }}</p>
                    <p>{{ formatDateSlash(item.checkInDate)}}</p>
                    <p>{{ item.facilityName }}</p>
                  </v-col>
                  <v-col cols="8" class="col-right">
                    <p class="text-right">検索対象: {{ item.keywordHitIn }}</p>
                    <p>{{ item.representativeName }}</p>
                    <p :style="item.representativeName ? '' : 'margin-top: 20px;'">
                      <span v-if="item.memberId">会員番号{{ item.memberId}}</span>
                      <span v-if="item.clientId">{{ item.memberId ? ' ': ''}}顧客ID{{ item.clientId}}</span>
                    </p>
                  </v-col>
                </v-row>
              </div>
            </template>

            <!-- 顧客ID - Customer ID -->
            <div class="pt-3 pl-3 pb-4" style="color: #212121; font-size: 18px; font-weight: 400">顧客ID</div>
            <div
              v-for="(item) in list.customerID"
              :key="item.id"
              class="pa-2 search-results__item"
            >
              <v-row :class="'search-row ' + itemRowBackgroundId(item)" @click="handleClickRow(item)">
                <v-col cols="4" class="col-left">
                  <p>{{ item.id }}</p>
                  <p>{{ formatDateSlash(item.checkInDate)}}</p>
                  <p>{{ item.facilityName }}</p>
                </v-col>
                <v-col cols="8" class="col-right">
                  <p class="text-right">検索対象: {{ item.keywordHitIn }}</p>
                  <p>{{ item.representativeName }}</p>
                  <p :style="item.representativeName ? '' : 'margin-top: 20px;'">
                    <span v-if="item.memberId">会員番号{{ item.memberId}}</span>
                    <span v-if="item.clientId">{{ item.memberId ? ' ': ''}}顧客ID{{ item.clientId}}</span>
                  </p>
                </v-col>
              </v-row>
            </div>

            <!-- ・宿泊備考欄（イレギュラー対応を備考に書き込む際に検索出来るようにしたい） if(list.accommodationRemarksColumn.length > 0) -->
            <!-- Accommodation remarks column (I want to be able to search when writing irregular correspondence in the remarks) -->
            <template v-if="list.accommodationRemarksColumn.length > 0">
              <div class="pt-3 pl-3 pb-4" style="color: #212121; font-size: 18px; font-weight: 400">宿泊備考欄</div>
              <div
                v-for="(item) in list.accommodationRemarksColumn"
                :key="item.id"
                class="pa-2 search-results__item"
              >
                <v-row :class="'search-row ' + itemRowBackgroundId(item)" @click="handleClickRow(item)">
                  <v-col cols="4" class="col-left">
                    <p>{{ item.id }}</p>
                    <p>{{ formatDateSlash(item.checkInDate)}}</p>
                    <p>{{ item.facilityName }}</p>
                  </v-col>
                  <v-col cols="8" class="col-right">
                    <p class="text-right">検索対象: {{ item.keywordHitIn }}</p>
                    <p>{{ item.representativeName }}</p>
                    <p :style="item.representativeName ? '' : 'margin-top: 20px;'">
                      <span v-if="item.memberId">会員番号{{ item.memberId}}</span>
                      <span v-if="item.clientId">{{ item.memberId ? ' ': ''}}顧客ID{{ item.clientId}}</span>
                    </p>
                  </v-col>
                </v-row>
              </div>
            </template>

            <!-- ・会員 ・副ファミリー会員 ・宿泊者個別の電話番号 -->
            <!-- Member - Deputy family member - Telephone number for each guest -->
            <div class="pt-3 pl-3 pb-4" style="color: #212121; font-size: 18px; font-weight: 400">電話番号</div>
            <div
              v-for="(item) in list.telephoneNumberForEachGuest"
              :key="item.id"
              class="pa-2 search-results__item"
            >
              <v-row :class="'search-row ' + itemRowBackgroundId(item)" @click="handleClickRow(item)">
                <v-col cols="4" class="col-left">
                  <p>{{ item.id }}</p>
                  <p>{{ formatDateSlash(item.checkInDate)}}</p>
                  <p>{{ item.facilityName }}</p>
                </v-col>
                <v-col cols="8" class="col-right">
                  <p class="text-right">検索対象: {{ item.keywordHitIn }}</p>
                  <p>{{ item.representativeName }}</p>
                  <p :style="item.representativeName ? '' : 'margin-top: 20px;'">
                    <span v-if="item.memberId">会員番号{{ item.memberId}}</span>
                    <span v-if="item.clientId">{{ item.memberId ? ' ': ''}}顧客ID{{ item.clientId}}</span>
                  </p>
                </v-col>
              </v-row>
            </div>

            <!-- ・会員 ・副ファミリー会員 ・宿泊者個別の名前 -->
            <!-- Member - Deputy family member - Individual name of the guest -->
            <div class="pt-3 pl-3 pb-4" style="color: #212121; font-size: 18px; font-weight: 400">名前</div>
            <div
              v-for="(item) in list.individualNameOfTheGuest"
              :key="item.id"
              class="pa-2 search-results__item"
            >
              <v-row :class="'search-row ' + itemRowBackgroundId(item)" @click="handleClickRow(item)">
                <v-col cols="4" class="col-left">
                  <p>{{ item.id }}</p>
                  <p>{{ formatDateSlash(item.checkInDate)}}</p>
                  <p>{{ item.facilityName }}</p>
                </v-col>
                <v-col cols="8" class="col-right">
                  <p class="text-right">検索対象: {{ item.keywordHitIn }}</p>
                  <p>{{ item.representativeName }}</p>
                  <p :style="item.representativeName ? '' : 'margin-top: 20px;'">
                    <span v-if="item.memberId">会員番号{{ item.memberId}}</span>
                    <span v-if="item.clientId">{{ item.memberId ? ' ': ''}}顧客ID{{ item.clientId}}</span>
                  </p>
                </v-col>
              </v-row>
            </div>

            <!-- ・会員 ・副ファミリー会員 ・宿泊者個別のメールアドレス -->
            <!-- Member - Deputy family member - E-mail address for each guest -->
            <div class="pt-3 pl-3 pb-4" style="color: #212121; font-size: 18px; font-weight: 400">メールアドレス</div>
            <div
              v-for="(item) in list.emailAddressForEachGuest"
              :key="item.id"
              class="pa-2 search-results__item"
            >
              <v-row :class="'search-row ' + itemRowBackgroundId(item)" @click="handleClickRow(item)">
                <v-col cols="4" class="col-left">
                  <p>{{ item.id }}</p>
                  <p>{{ formatDateSlash(item.checkInDate)}}</p>
                  <p>{{ item.facilityName }}</p>
                </v-col>
                <v-col cols="8" class="col-right">
                  <p class="text-right">検索対象: {{ item.keywordHitIn }}</p>
                  <p>{{ item.representativeName }}</p>
                  <p :style="item.representativeName ? '' : 'margin-top: 20px;'">
                    <span v-if="item.memberId">会員番号{{ item.memberId}}</span>
                    <span v-if="item.clientId">{{ item.memberId ? ' ': ''}}顧客ID{{ item.clientId}}</span>
                  </p>
                </v-col>
              </v-row>
            </div>

          </div>
          <div v-else class="text-center pa-5">
            {{ $t("common.noResults") }}
          </div>
          </v-card-text>
        </v-card>
        <!-- <v-data-table
          :items="listSearchBooking"
          @current-items="getListSearch"
          :search="searchAlllValue"
          :headers="headers"
          class="d-none"
          :no-data-text="$t('rules.noData')"
        ></v-data-table> -->
        <!-- End search All -->

        <v-btn :disabled="!checkUser" class="btn--primary ml-5" @click.stop="dialog = true">
          <v-icon class="mr-1"> mdi-plus </v-icon>
          <span>{{ $t("buttons.newRoomReservation") }}</span>
        </v-btn>
        <!-- Start Dialog -->
        <v-dialog v-model="dialog" max-width="400" content-class="modal-custom">
          <v-card class="modal-custom_content">
            <v-card-title class="text-h6 font-weight-bold pb-0">
              {{ $t("modalReservation.title") }}
            </v-card-title>
           <v-form ref="form">
              <v-card-text class="pb-0">
              <v-text-field label="顧客ID/会員番号" autofocus v-model="clientByIdOrMemberId" :rules="rules.inputId"></v-text-field>
            </v-card-text>
           </v-form>

            <v-card-actions class="pt-0 pb-4 px-5 d-block">
              <v-row>
                <v-col cols="6" class="pb-0 pr-2">
                  <v-btn
                    color="primary"
                    class="w-100"
                    target="_blank"
                    @click="submitAction()"
                  >
                    {{ $t("modalReservation.btnReservation") }}
                  </v-btn>
                </v-col>
                <v-col cols="6" class="pb-0 pl-2">
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pb-0 pr-2">
                  <v-btn color="primary" class="w-100"
                    to="/reservation/calendar-block?type=reservation-without-customer-ID"
                    target="_blank"
                    @click="dialog = false">
                    {{ $t("modalReservation.btnReservationList") }}
                  </v-btn>
                </v-col>
                <v-col cols="6" class="pb-0 pl-2">
                  <v-btn
                    class="w-100 btn-custom-dark"
                    to="/reservation/calendar-block?type=block"
                    target="_blank"
                    @click="dialog = false"
                  >
                    {{ $t("modalReservation.btnReservationBlock") }}
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="error"
                    class="btn-close"
                    @click="dialog = false"
                  >
                    {{ $t("buttons.close") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- End Dialog -->
      </div>
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions'
import { getBackgroundColorOfBookingTypeId } from '@/constants/contractColors'
import { formatDateSlash } from '@/constants/functions'
import { GET_CLIENT_BY_ID_OR_MEMBER_ID } from '@/api/graphql/guestRoomDailyReport/reservation-list'
import { mapMutations, mapActions } from 'vuex'
import gql from 'graphql-tag'

export default {
  name: 'search-booking',
  data () {
    return {
      rules: {
        inputId: [v => !!v || this.$t('rules.isRequired'),
          v => v?.length < 256 || this.$t('rules.isInvalid')]
      },
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      dialog: false,
      showListSearch: false,
      listSearchBooking: [],
      list: {
        // ・予約ID
        // Reservation ID
        reservationID: [],

        // ・会員ID
        // Member ID
        memberID: [],

        // ・顧客ID
        // Customer ID
        customerID: [],

        // ・宿泊備考欄（イレギュラー対応を備考に書き込む際に検索出来るようにしたい）
        // Accommodation remarks column (I want to be able to search when writing irregular correspondence in the remarks)
        accommodationRemarksColumn: [],

        // ・会員 ・副ファミリー会員 ・宿泊者個別の名前
        // Member - Deputy family member - Individual name of the guest
        individualNameOfTheGuest: [],

        // ・会員 ・副ファミリー会員 ・宿泊者個別の電話番号
        // Member - Deputy family member - Telephone number for each guest
        telephoneNumberForEachGuest: [],

        // ・会員 ・副ファミリー会員 ・宿泊者個別のメールアドレス
        // Member - Deputy family member - E-mail address for each guest
        emailAddressForEachGuest: []
      },
      clientByIdOrMemberId: '',
      keyword: '',
      searchTimer: null
    }
  },
  watch: {
    keyword: function (val) {
      this.handleSearchBookingList()
    },
    dialog () {
      if (this.clientByIdOrMemberId) {
        this.$refs.form.reset()
      }
    }
  },
  methods: {
    ...mapActions(['actionSearchBookingList']),
    ...mapMutations(['setReversion']),
    formatDateSlash,
    onClickOutside () {
      this.showListSearch = false
    },
    handleClickRow (item) {
      window.open('/reservation/contract-detail?id=' + item.id + '&tab=basic-information', '_blank')
    },
    async getClientByIdOrMemberId () {
      await this.$apollo.query({
        query: gql`${GET_CLIENT_BY_ID_OR_MEMBER_ID}`,
        variables: {
          id: this.clientByIdOrMemberId
        }
      }).then((data) => {
        const reversion = data.data.getClientByIdOrMemberId
        this.setReversion({ reversion: reversion })
        localStorage.setItem('reversion', JSON.stringify(data.data.getClientByIdOrMemberId))
        localStorage.setItem('statusSetCalenDar', JSON.stringify(false))
        localStorage.setItem('statusAddGroup', JSON.stringify(false))
      }).catch((error) => {
        console.error(error)
      })
    },
    submitAction () {
      if (this.$refs.form.validate()) {
        this.getClientByIdOrMemberId()
        localStorage.setItem('status', JSON.stringify(false))
        // this.$router.push(`/reservation/calendar?id=${this.clientByIdOrMemberId}`)
        window.open(`/reservation/calendar?id=${this.clientByIdOrMemberId}`, '_blank')
        this.dialog = false
      }
    },
    handleSearchBookingList () {
      if (this.keyword === undefined || this.keyword === null) {
        this.keyword = ''
      }
      if (this.keyword) { // don't search without keyword because it's useless
        // prevent too many requests by delaying the actual search
        // check if the previous request exists, and if does remove it
        const keyword = this.keyword
        if (this.searchTimer) {
          clearTimeout(this.searchTimer)
          this.searchTimer = null
        }

        const timeout = setTimeout((...args) => {
          this.actionSearchBookingList(keyword).then((bookingList) => {
            if (this.searchTimer !== timeout) {
              // this means that the time was cancelled by another search request, so just don't do anything
              return
            } else {
              this.searchTimer = null
            }
            // list result
            this.listSearchBooking = bookingList
            // show v-card
            this.showListSearch = true
            // list result split
            const reservationIDList = []
            const memberIDList = []
            const customerIDList = []
            const accommodationRemarksColumnList = []
            const individualNameOfTheGuestList = []
            const telephoneNumberForEachGuestList = []
            const emailAddressForEachGuestList = []
            bookingList.forEach(element => {
              switch (element.keywordHitIn) {
                // ・予約ID
                case '予約ID':
                  reservationIDList.push(element)
                  break
                // ・会員ID
                case '会員番号':
                  memberIDList.push(element)
                  break
                // ・顧客ID
                case '顧客ID':
                  customerIDList.push(element)
                  break
                // ・宿泊備考欄（イレギュラー対応を備考に書き込む際に検索出来るようにしたい）- ????????
                case '宿泊備考欄':
                  accommodationRemarksColumnList.push(element)
                  break
                // ・会員 ・副ファミリー会員 ・宿泊者個別の名前
                case '宿泊者名':
                  individualNameOfTheGuestList.push(element)
                  break
                // ・会員 ・副ファミリー会員 ・宿泊者個別の電話番号
                case '宿泊者電話':
                  telephoneNumberForEachGuestList.push(element)
                  break
                // ・会員 ・副ファミリー会員 ・宿泊者個別のメールアドレス
                case '宿泊者メール':
                  emailAddressForEachGuestList.push(element)
                  break
                default:
                  break
              }
            })
            this.list.reservationID = reservationIDList
            this.list.memberID = memberIDList
            this.list.customerID = customerIDList
            this.list.accommodationRemarksColumn = accommodationRemarksColumnList
            this.list.individualNameOfTheGuest = individualNameOfTheGuestList
            this.list.telephoneNumberForEachGuest = telephoneNumberForEachGuestList
            this.list.emailAddressForEachGuest = emailAddressForEachGuestList
          }).catch((er) => {
            // list result
            this.listSearchBooking = []
            // list result split
            this.list.reservationID = []
            this.list.memberID = []
            this.list.customerID = []
            this.list.accommodationRemarksColumn = []
            this.list.individualNameOfTheGuest = []
            this.list.telephoneNumberForEachGuest = []
            this.list.emailAddressForEachGuest = []
            // show v-card
            this.showListSearch = true
          })
        }, 500)
        this.searchTimer = timeout
      }
    },
    itemRowBackgroundId (item) {
      const bookingTypeId = item.bookingTypeId
      return getBackgroundColorOfBookingTypeId(bookingTypeId)
    }
  }
}
</script>

<style scoped lang="scss">
.p-relative {
  position: relative;
}
.search-text-field{
  ::v-deep{
    .v-text-field__slot{
      input{
        font-size: 18px;
        font-weight: 400;
        color: #212121 !important;
      }
    }
    .v-text-field--placeholder{
      ::placeholder{
        color: #AAAAAA  !important;
      }
    }
  }
}
.search-results {
    position: absolute;
    top: calc(100% - 10px);
    right: 100px;
    width: 420px;
    max-height: 552px;
    overflow-x: hidden;
    z-index: 100;
    &__item:not(:last-child) {
      border-bottom: solid 1px var(--border__white);
    }
  }
.search-row{
  cursor: pointer;
  .col-left{
    p{
      margin-bottom: 0;
      color: #000;
      &:nth-child(1){
        font-weight: 400;
        font-size: 18px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &:nth-child(2){
        font-weight: 400;
        font-size: 16px;
      }
      &:nth-child(3){
        font-weight: 400;
        font-size: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .col-right{
    p{
      margin-bottom: 0;
      color: #000;
      &:nth-child(2){
        font-size: 16px;
        overflow: hidden;
      }
      &:nth-child(2){
        font-weight: 400;
        font-size: 18px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &:nth-child(3){
        font-weight: 400;
        font-size: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
</style>
